import axios from 'axios';
import { setHeaderAuth } from "../utils";

const GetList = async (data, activity_detail = '') => {
    await setHeaderAuth();
    let res = await axios({
        url: `/tranJob/getOnsite`,
        method: "post",
        data: data,
        headers: {
            activity_detail: activity_detail
        }
    });
    return res;
};

const GetJobDetail = async (activity_detail = '', job_no) => {
    await setHeaderAuth();
    let res = await axios({
        url: `/tranJob/getJob/${job_no}`,
        method: "get",
        // data: data,
        headers: {
            activity_detail: activity_detail
        }
    });
    return res;
};
const SaveCompare = async (data, activity_detail = '') => {
    await setHeaderAuth();
    let res = await axios({
        url: `/tranJob/savecompare`,
        method: data.id > 0 ? "put" : "post",
        data: data,
        headers: {
            activity_detail: activity_detail
        }
    });
    return res;
};
const SaveJobStatus = async (data, activity_detail = '') => {
    await setHeaderAuth();
    let res = await axios({
        url: `/tranJob/savestatus`,
        method: "post",
        data: data,
        headers: {
            activity_detail: activity_detail
        }
    });
    return res;
};

const uploadFile = async (data,object,activity_detail = '') => {
    await setHeaderAuth();
    let res = await axios({
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: `/tranJob/uploadCompare`,
        method: "post",
        data: data,
        headers: {
          activity_detail: activity_detail,
          // object: object
        }
    });
    return res;
};

const apiTranJob = {
    GetList: GetList,
    GetJobDetail: GetJobDetail,
    SaveCompare: SaveCompare,
    SaveJobStatus: SaveJobStatus,
    uploadFile: uploadFile,
};

export default apiTranJob;