import axios from "axios";
import { setHeaderAuth, setHeaderFromDataAuth } from "../utils";

const GetList = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterRoadSurface/get`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const GetById = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterRoadSurface/getById`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const InsertMtRoadSurface = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterRoadSurface/insert`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateMtRoadSurface = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterRoadSurface/update`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateStatus = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterRoadSurface/status`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const DeleteMtRoadSurface = async (id,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterRoadSurface/${id}`,
      method: "delete",
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const ImportFile = async (data, activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      headers: {
          'Content-Type': 'multipart/form-data',
          activity_detail: activity_detail
      },
      url: `masterRoadSurface/import`,
      method: "post",
      data: data
  });
  return res;
};
const Export = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterRoadSurface/export`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};

const apiMasterRoadSurface = {
  GetList: GetList,
  GetById: GetById,
  InsertMtRoadSurface: InsertMtRoadSurface,
  UpdateMtRoadSurface: UpdateMtRoadSurface,
  UpdateStatus: UpdateStatus,
  DeleteMtRoadSurface: DeleteMtRoadSurface,
  Export: Export,
  ImportFile: ImportFile,
};
export default apiMasterRoadSurface;