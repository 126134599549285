import axios from "axios";
import { setHeaderAuth, setHeaderFromDataAuth } from "../utils";

const GetList = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterLandcond/get`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const GetById = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterLandcond/getById`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const InsertMtLandcond = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandcond/insert`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateMtLandcond = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandcond/update`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateStatus = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandcond/status`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const DeleteMtLandcond = async (id,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandcond/${id}`,
      method: "delete",
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const ImportFile = async (data, activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      headers: {
          'Content-Type': 'multipart/form-data',
          activity_detail: activity_detail
      },
      url: `masterLandcond/import`,
      method: "post",
      data: data
  });
  return res;
};
const Export = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandcond/export`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};

const apiMasterLandcond = {
  GetList: GetList,
  GetById: GetById,
  InsertMtLandcond: InsertMtLandcond,
  UpdateMtLandcond: UpdateMtLandcond,
  UpdateStatus: UpdateStatus,
  DeleteMtLandcond: DeleteMtLandcond,
  Export: Export,
  ImportFile: ImportFile,
};
export default apiMasterLandcond;