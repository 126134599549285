import moment from "moment";
import 'moment/locale/th';
import { useState } from "react";
import { StaticImage } from "../../utils/image";
import { MapPinIcon } from "@heroicons/react/24/outline";



const History = () => {
    const [state, setState] = useState([
        {
            customer_name: "นายอัชพล พึ่งเสมา",
            receive_date: moment().format("DD MMMM yyyy"),
            doc_no: "AG66081752203",
            address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
            description: "เพื่อประกอบการพิจารณด้านสินเชื่อ",
            department: "ลาดพร้าว 6",
            status: "Complete",
            img: "home2.jpeg",
        },
        {
            customer_name: "นายนพรัตน์ บุญไชย",
            receive_date: moment().format("DD MMMM yyyy"),
            doc_no: "AG66081752202",
            address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
            description: "เพื่อประกอบการพิจารณด้านสินเชื่อ",
            department: "ลาดพร้าว 6",
            status: "Send Request to Assessor",
            img: "home1.jpeg",
        },
        {
            customer_name: "นายอัชพล พึ่งเสมา",
            receive_date: moment().format("DD MMMM yyyy"),
            doc_no: "AG66081752204",
            address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
            description: "เพื่อประกอบการพิจารณด้านสินเชื่อ",
            department: "ลาดพร้าว 6",
            status: "Complete",
            img: "home2.jpeg",
        },
        {
            customer_name: "นายอัชพล พึ่งเสมา",
            receive_date: moment().format("DD MMMM yyyy"),
            doc_no: "AG66081752205",
            address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
            description: "เพื่อประกอบการพิจารณด้านสินเชื่อ",
            department: "ลาดพร้าว 6",
            status: "Complete",
            img: "home2.jpeg",
        },
    ]);

    return <div className="p-3 py-4">
        <div className="flex flex-col gap-y-4">
            {state.map((item, index) => {
                return <CardItem item={item} />
            })}
        </div>
    </div>
}

const CardItem = ({ item }) => {
    return <div className={`border bg-white ${item.status == "Send Request to Assessor" ? "border-l-yellow-500" : "border-l-green-500"} 
    border-l-4 rounded py-2 px-3 flex gap-x-4`}>
        <div className="flex flex-col gap-y-2">
            <img className="w-20 h-20" src={StaticImage(`/images/${item.img}`)} />
            <button className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">รายละเอียด</button>
        </div>
        <div className="flex flex-col">
            <h4 className="text-xl">{item.customer_name} <span className="text-gray-600">({item.doc_no})</span></h4>
            <span className="text-gray-600">{item.description}</span>
            <div className="flex gap-x-2 pb-1">
                <span className="text-gray-600 text-sm">{item.address}</span>
            </div>
            <div className="flex justify-between border-t pt-1">
                <span className="text-sm text-gray-600">{item.status}</span>
                <span className="text-sm text-gray-600">{item.receive_date}</span>
            </div>
        </div>
    </div>
}

export default History;