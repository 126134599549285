import axios from "axios";
import { setHeaderAuth, setHeaderFromDataAuth } from "../utils";

const GetList = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterLandBenefit/get`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const GetById = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterLandBenefit/getById`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const InsertMtLandBenefit = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandBenefit/insert`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateMtLandBenefit = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandBenefit/update`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateStatus = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandBenefit/status`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const DeleteMtLandBenefit = async (id,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandBenefit/${id}`,
      method: "delete",
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const ImportFile = async (data, activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      headers: {
          'Content-Type': 'multipart/form-data',
          activity_detail: activity_detail
      },
      url: `masterLandBenefit/import`,
      method: "post",
      data: data
  });
  return res;
};
const Export = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterLandBenefit/export`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};

const apiMasterLandBenefit = {
  GetList: GetList,
  GetById: GetById,
  InsertMtLandBenefit: InsertMtLandBenefit,
  UpdateMtLandBenefit: UpdateMtLandBenefit,
  UpdateStatus: UpdateStatus,
  DeleteMtLandBenefit: DeleteMtLandBenefit,
  Export: Export,
  ImportFile: ImportFile,
};
export default apiMasterLandBenefit;