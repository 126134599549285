import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// import {
//     faHomeAlt,
//     faTools,
//     faHistory,
// } from "@fortawesome/free-solid-svg-icons";

const NavbarMobile = () => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState("");
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);
    return (
        <div className="flex fixed w-full bottom-0 bg-green-800 text-white  text-3xl   divide-x-2 divide-gray-dark">
            {
                !url.includes("newItem") && !url.includes('detail') && <>
                    <Link to={`/`} className="flex-grow flex-shrink-0 w-4/12">
                        <div className={`flex justify-center items-center gap-x-2 py-3 ${!url.includes("history") ? "underline" : ""}`}>
                            ข้อมูลงาน
                        </div>
                    </Link>
                    <Link to={`/history`} className="flex-grow flex-shrink-0 w-4/12">
                        <div className={`flex justify-center items-center gap-x-2 py-3 ${url.includes("history") ? "underline" : ""}`}>
                            ประวัติงาน
                        </div>
                    </Link>
                </>
            }

        </div>
    );
};

export default NavbarMobile;
