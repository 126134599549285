import { Tab, Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import apiTranJob from "../../api/apiTranJob";
import apiMasterJobType from "../../api/apiMasterJobType";
import apiMasterBuildingType from "../../api/apiMasterBuildingType";
import apiMasterUnit from "../../api/apiMasterUnit";
import apiMasterFloor from "../../api/apiMasterFloor";
import apiMasterBranch from "../../api/apiMasterBranch";
import apiMasterAvgLevel from "../../api/apiMasterAvgLevel";
import apiMasterRoadSurface from "../../api/apiMasterRoadSurface";
import apiMasterUtility from "../../api/apiMasterUtility";
import apiMasterEnvironment from "../../api/apiMasterEnvironment";
import apiMasterCityPlan from "../../api/apiMasterCityPlan";
import apiMasterLandBenefit from "../../api/apiMasterLandBenefit";
import apiMasterDevPotential from "../../api/apiMasterDevPotential";
import apiMasterSellStatus from "../../api/apiMasterSellStatus";
import apiMasterLandcond from "../../api/apiMasterLandcond";
import apiMasterLandform from "../../api/apiMasterLandform";
import apiMasterImageType from "../../api/apiMasterImageType";
import CSwl from "../../utils/alert";
import { IMAGE_BACKEND_API } from "./../../env";
import { CheckFile } from "../../utils/uploadfile";
import { PencilSquareIcon, PlusIcon, TrashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const NewItem = () => {
    const [jobRef, setJobRef] = useState((new URL(window.location)).searchParams.get("job"));
    const [id, setId] = useState((new URL(window.location)).searchParams.get("id"));
    const [ind, set_ind] = useState((new URL(window.location)).searchParams.get("ind"));
    const [job_state, set_job_state] = useState({});
    const [jobCompareData, setJobCompareData] = useState([])
    const [job_compare_state, set_job_compare_state] = useState(
        {
            id: 0,
            job_no: jobRef,
            image_json: [],
            compare_name: "ข้อมูลเปรียบเทียบที่ ",
            property_type: "",
            building_type: "",
            floor_amount: "",
            unit_amount: 0,
            unit_unit: "",
            usage_area: 0,
            building_size_width: null,
            building_size_length: null,
            building_age: 0,
            building_address: "",
            lat: "",
            long: "",
            land_area_rai1: 0,
            land_area_rai2: 0,
            land_area_rai3: 0,
            land_area_wah: 0,
            land_type: "",
            land_size_width: 0,
            land_size_length: 0,
            land_condition: "",
            ground_level: "",
            ground_level_amount: 0,
            road_conditions: "",
            road_conditions_amount: 0,
            utilities: "",
            facilities: "",
            environment: "",
            city_plan_limit: "",
            land_usage: "",
            development_potential: "",
            price: null,
            sale_status: "",
            inform_date: null,
            informer: "",
            phone: "",
            remark: "",
            cover_image: "",
        });
    const [jobtype_state, set_jobtype_state] = useState([]);
    const [building_type_state, set_building_type_state] = useState([]);
    const [unit_state, set_unit_state] = useState([]);
    const [floor_state, set_floor_state] = useState([]);
    const [avg_level_state, set_avg_level_state] = useState([]);
    const [road_surface_state, set_road_surface_state] = useState([]);
    const [utility_state, set_utility_state] = useState([]);
    const [environment_state, set_environment_state] = useState([]);
    const [city_plan_state, set_city_plan_state] = useState([]);
    const [land_benefit_state, set_land_benefit_state] = useState([]);
    const [dev_potential_state, set_dev_potential_state] = useState([]);
    const [sell_status_state, set_sell_status_state] = useState([]);
    const [land_condition_state, set_land_condition_state] = useState([]);
    const [land_form_state, set_land_form_state] = useState([]);
    const [image_type_state, set_image_type_state] = useState([]);
    const navigate = useNavigate();
    const [state, setState] = useState({
        picture: [],
        picture2: [],
        picture3: []
    });

    useEffect(() => {
        if (jobRef && jobRef.length > 0) {
            getDetail();
        }
        getJobType();
        getBuildingType();
        getUnit();
        getFloor();
        getAvgLevel();
        getRoadSurface();
        getUtility();
        getEnvironment();
        getCityPlan();
        getLandBenefit();
        getDevPotential();
        getSellStatus();
        getLandCondition();
        getLandForm();
        getImageType();
        // func_add_log("Click Menu Invoice Detail Report", "{}");
    }, []);

    useEffect(() => {
        if (job_compare_state) {
            var job_state_data = { ...job_compare_state };
            var land_area_rai1 = parseFloat(job_state_data.land_area_rai1);
            var land_area_rai2 = parseFloat(job_state_data.land_area_rai2);
            var land_area_rai3 = parseFloat(job_state_data.land_area_rai3);

            if (land_area_rai3 >= 100) {
                var tmp_rai3 = (land_area_rai3 % 100)
                var tmp_rai2 = land_area_rai2;
                tmp_rai2 += ((land_area_rai3 - (land_area_rai3 % 100)) / 100);
                land_area_rai3 = tmp_rai3;
                land_area_rai2 = tmp_rai2
            }

            if (land_area_rai2 >= 4) {
                var tmp_rai2 = (land_area_rai2 % 4)
                var tmp_rai1 = land_area_rai1;
                tmp_rai1 += ((land_area_rai2 - (land_area_rai2 % 4)) / 4);
                land_area_rai2 = tmp_rai2;
                land_area_rai1 = tmp_rai1;
            }
            job_state_data.land_area_rai3 = land_area_rai3;
            job_state_data.land_area_rai2 = land_area_rai2;
            job_state_data.land_area_rai1 = land_area_rai1;
            var land_area_wah = (land_area_rai1 * 400) + (land_area_rai2 * 100) + (land_area_rai3)
            job_state_data.land_area_wah = land_area_wah;

            set_job_compare_state(job_state_data)
        }
        // func_add_log("Click Menu Invoice Detail Report", "{}");
    }, [job_compare_state.land_area_rai1, job_compare_state.land_area_rai2, job_compare_state.land_area_rai3]);

    const getDetail = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiTranJob.GetJobDetail("", jobRef);
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                console.log("data::", data);
                var job_compare = [...data.job_compare_data];
                for (let i = 0; i < job_compare.length; i++) {
                    var item = job_compare[i];
                    if (!item.image_json) {
                        item.image_json = [];
                    }
                    if (item.image_json == "[]" || item.image_json == "\"[]\"" || item.image_json == "" || item.image_json == null) {
                        item.image_json = [];
                    }
                    while (typeof (item.image_json) != typeof ({})) {
                        item.image_json = JSON.parse(item.image_json);
                    }
                }
                console.log("id::", id);
                console.log("job_compare::", job_compare);
                setJobCompareData(job_compare);
                if (id && id != 0) {
                    var onStage = job_compare.find((x) => x.id == id)
                    console.log("onStage::", onStage);
                    set_job_compare_state(onStage);
                } else {
                    if (job_compare.length >= 2) {
                        var bfData = job_compare[job_compare.length-1]
                        set_job_compare_state({
                            image_json: [],
                            index: job_compare.length + 1,
                            id: 0,
                            compare_name: "ข้อมูลเปรียบเทียบที่ ",
                            property_type: bfData.property_type,
                            building_type: bfData.building_type,
                            floor_amount: bfData.floor_amount,
                            unit_amount: bfData.unit_amount,
                            unit_unit: bfData.unit_unit,
                            usage_area: bfData.usage_area,
                            building_size_width: bfData.building_size_width,
                            building_size_length: bfData.building_size_length,
                            building_age: bfData.building_age,
                            building_address: bfData.building_address,
                            lat: "",
                            long: "",
                            land_area_rai1: bfData.land_area_rai1,
                            land_area_rai2: bfData.land_area_rai2,
                            land_area_rai3: bfData.land_area_rai3,
                            land_area_wah: bfData.land_area_wah,
                            land_type: bfData.land_type,
                            land_size_width: bfData.land_size_width,
                            land_size_length: bfData.land_size_length,
                            land_condition: bfData.land_condition,
                            ground_level: bfData.ground_level,
                            ground_level_amount: bfData.ground_level_amount,
                            road_conditions: bfData.road_conditions,
                            road_conditions_amount: bfData.road_conditions_amount,
                            utilities: bfData.utilities,
                            facilities: bfData.facilities,
                            environment: bfData.environment,
                            city_plan_limit: bfData.city_plan_limit,
                            land_usage: bfData.land_usage,
                            development_potential: bfData.development_potential,
                            price: null,
                            sale_status: "",
                            inform_date: null,
                            informer: "",
                            phone: "",
                            remark: "",
                        });
                    }
                }
                delete data.job_compare_data;
                delete data.deed_data;
                delete data.sign_data;
                delete data.bd_valuation_json;
                set_job_state(data);
                // var pdata = [];
                // for (let i = 0; i < data.rows.length; i++) {
                //     var item = data.rows[i];
                //     pdata.push({
                //         value: item.contractname,
                //         label: item.contractname,
                //     })
                // }
                // setCustomerOptionData(pdata)
                // setCustomerData(data);
            }
        } catch (error) {

        }
    }

    const getJobType = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterJobType.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.jobtype,
                        label: item.jobtype,
                    })
                }
                set_jobtype_state(pdata);
            }
        } catch (error) {

        }
    }

    const getBuildingType = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterBuildingType.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.buildingtype,
                        label: item.buildingtype,
                    })
                }
                set_building_type_state(pdata);
            }
        } catch (error) {

        }
    }

    const getUnit = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterUnit.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.unit,
                        label: item.unit,
                    })
                }
                set_unit_state(pdata);
            }
        } catch (error) {

        }
    }

    const getFloor = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterFloor.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.floor,
                        label: item.floor,
                    })
                }
                set_floor_state(pdata);
            }
        } catch (error) {

        }
    }

    const getAvgLevel = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterAvgLevel.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.avg_level,
                        label: item.avg_level,
                    })
                }
                set_avg_level_state(pdata);
            }
        } catch (error) {

        }
    }

    const getRoadSurface = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterRoadSurface.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.roadsurface,
                        label: item.roadsurface,
                    })
                }
                set_road_surface_state(pdata);
            }
        } catch (error) {

        }
    }

    const getUtility = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterUtility.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.utility,
                        label: item.utility,
                    })
                }
                set_utility_state(pdata);
            }
        } catch (error) {

        }
    }

    const getEnvironment = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterEnvironment.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.environment,
                        label: item.environment,
                    })
                }
                set_environment_state(pdata);
            }
        } catch (error) {

        }
    }

    const getCityPlan = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterCityPlan.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.cityplan,
                        label: item.cityplan,
                    })
                }
                set_city_plan_state(pdata);
            }
        } catch (error) {

        }
    }

    const getLandBenefit = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterLandBenefit.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.landbenefit,
                        label: item.landbenefit,
                    })
                }
                set_land_benefit_state(pdata);
            }
        } catch (error) {

        }
    }

    const getDevPotential = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterDevPotential.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.dev_potential,
                        label: item.dev_potential,
                    })
                }
                set_dev_potential_state(pdata);
            }
        } catch (error) {

        }
    }

    const getSellStatus = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterSellStatus.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.sellstatus,
                        label: item.sellstatus,
                    })
                }
                set_sell_status_state(pdata);
            }
        } catch (error) {

        }
    }

    const getLandCondition = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterLandcond.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.landcond,
                        label: item.landcond,
                    })
                }
                set_land_condition_state(pdata);
            }
        } catch (error) {

        }
    }

    const getLandForm = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterLandform.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                var pdata = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var item = data.rows[i];
                    pdata.push({
                        value: item.landform,
                        label: item.landform,
                    })
                }
                set_land_form_state(pdata);
            }
        } catch (error) {

        }
    }

    const getImageType = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiMasterImageType.GetList();
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                set_image_type_state(data.rows);
            }
        } catch (error) {

        }
    }

    const func_attachFile = async (e, image_type) => {
        if (e.files.length > 0) {
            var file = e.files[0];

            var type = ["image/png", "image/jpg", "image/jpeg"];
            var message = "";
            var check = CheckFile({
                file,
                size: 10,
                type: type,
                message,
            });
            if (check) {
                var formdata = new FormData();
                formdata.append("file", file);
                var object = {};
                formdata.forEach(function (value, key) {
                    object[key] = value;
                });
                // console.log(object)
                uploadFile(formdata, JSON.stringify(object), image_type);
            }
        }
    }

    const uploadFile = async (data, obj, image_type) => {
        try {
            const result = await apiTranJob.uploadFile(data, JSON.stringify({}), "Upload image");
            if (result.status === 200) {
                const { path } = result.data;
                console.log("upload result path::", path);
                if (image_type) {
                    var state = { ...job_compare_state };
                    console.log("state::", state);
                    if (!state.image_json) {
                        state.image_json = [];
                    }
                    var oldData = [...state.image_json];
                    console.log("oldData::", oldData);
                    if (ind != 0 && oldData.length == 0) {
                        state.cover_image = path;
                    }
                    oldData.push({
                        image: path,
                        type_id: image_type.id,
                        type_name: image_type.image_type,
                    })
                    console.log("oldData:new:", oldData);
                    state.image_json = oldData;
                    console.log("state:new:", state);
                    set_job_compare_state(state);
                } else {
                    set_job_compare_state({ ...job_compare_state, cover_image: path });
                    set_job_state({ ...job_state, cover_image: path });
                }
                // toggleNew();
                // CSwl.SwalSuccess("บันทึกสำเร็จ")
                // .then((success) => {
                //     refreshData("Refresh Data Tran Ct Banner");
                // });
            }
        } catch (error) {
            CSwl.SwalErr(error.response.data)
        }
    }

    const on_submit_compare = (e) => {
        e.preventDefault();
        var data = { ...job_compare_state };
        if ((!data.image_json) || data.image_json.length == 0) {
            data.image_json = '[]'
        }
        var temp1 = JSON.stringify(data.image_json);
        var temp2 = JSON.parse(temp1);
        data.image_json = JSON.stringify(temp2);
        data.is_save_job = (ind == 0);

        saveCompareData(data);
    };

    const saveCompareData = async (data) => {
        try {
            const result = await apiTranJob.SaveCompare(data, "Save Compare job Data");
            console.log(result)
            if (result.status === 200) {
                // const { data } = result.data;
                // toggle();
                CSwl.SwalSuccess("บันทึกสำเร็จ").then((success) => {
                    navigate(`/detail?job=${jobRef}`)
                });
            }
        } catch (error) {
            CSwl.SwalErr(error.response.data)
        }
    };

    const func_getCurrentLocation = () => {
        try {
            navigator.geolocation.getCurrentPosition(async function (position) {
                try {
                    var oldData = { ...job_compare_state };
                    oldData.lat = position.coords.latitude.toString();
                    oldData.long = position.coords.longitude.toString();
                    set_job_compare_state(oldData);
                } catch (error) {
                }
            });
        } catch (error) {

        }
    }

    return <div className="p-3 py-4">
        <div>
            <Tab.Group>
                <Tab.List className={"flex"}>
                    <Tab className="flex-1" as={Fragment}>
                        {({ selected }) => (
                            <button
                                className={
                                    `rounded-l text-lg py-1
                                    ${selected ? 'bg-gray-800 text-white' : 'bg-white text-black'}
                                    `
                                }
                            >
                                1. ภาพถ่าย
                            </button>
                        )}
                    </Tab>
                    <Tab className="flex-1" as={Fragment}>
                        {({ selected }) => (
                            <button
                                className={
                                    `rounded-r text-lg
                                    ${selected ? 'bg-gray-800 text-white' : 'bg-white text-black'}
                                    `
                                }
                            >
                                2. ข้อมูล
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels className={"py-4"}>
                    <Tab.Panel className={"flex gap-y-4 flex-col"}>
                        {ind == 0 && (
                            <Disclosure defaultOpen>
                                {({ open }) => (
                                    <div>
                                        <Disclosure.Button className={"flex btn-green !bg-green-800 items-center justify-between w-full"}>
                                            รูปหน้าปก
                                            <ChevronRightIcon className={open ? 'rotate-90 transform h-4' : 'h-4'} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className={"px-2 pt-2"}>
                                            <div className="flex flex-col">
                                                <div className="flex items-center gap-x-3 flex-wrap gap-2">
                                                    {job_compare_state.cover_image && job_compare_state.cover_image.length > 0 && (
                                                        <div key={`picture-0-0`} className="flex-shrink-0 flex justify-center items-center w-40 h-32">
                                                            <img
                                                                src={IMAGE_BACKEND_API + job_compare_state.cover_image}
                                                                alt={`picture-0-0`}
                                                                className="border-2 bg-white border-gray-light border-dashed w-full h-full rounded-lg"
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="flex-shrink-0 flex justify-center items-center h-32 w-40 mt-2">
                                                        <label
                                                            htmlFor={"dropzone-file-client"}
                                                            className="flex flex-col justify-center items-center w-full p-5 bg-gray-100 rounded-lg border-2 border-gray-light border-dashed cursor-pointer "
                                                        >
                                                            <div className="flex flex-col justify-center items-center">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="mb-3 w-6 h-6 text-gray-light"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    viewBox="0 0 24 24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                                    ></path>
                                                                </svg>
                                                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                                                    <span className="font-semibold">
                                                                        Click to upload
                                                                    </span>
                                                                </p>
                                                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                                                    or drag and drop
                                                                </p>
                                                            </div>
                                                            <input
                                                                id={"dropzone-file-client"}
                                                                type="file"
                                                                className="hidden"
                                                                name={"dropzone-file-client"}
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => {
                                                                    func_attachFile(e.target);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>
                        )}
                        {image_type_state.map((x) => (
                            <Disclosure defaultOpen>
                                {({ open }) => (
                                    <div>
                                        <Disclosure.Button className={"flex btn-green !bg-green-800 items-center justify-between w-full"}>
                                            {x.image_type}
                                            <ChevronRightIcon className={open ? 'rotate-90 transform h-4' : 'h-4'} />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className={"px-2 pt-2"}>
                                            <div className="flex flex-col">
                                                <div className="flex items-center gap-x-3 flex-wrap gap-2">
                                                    {(job_compare_state.image_json.filter((c) => c.type_id == x.id)).length > 0 &&
                                                        (job_compare_state.image_json.filter((c) => c.type_id == x.id)).map((item, index) => {
                                                            return (
                                                                <div key={`picture-${x.id}-${index}`} className="flex-shrink-0 flex justify-center items-center w-40 h-32">
                                                                    <img
                                                                        src={IMAGE_BACKEND_API + item.image}
                                                                        alt={`pic-${x.id}-${index}`}
                                                                        className="border-2 bg-white border-gray-light border-dashed w-full h-full rounded-lg"
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    <div className="flex-shrink-0 flex justify-center items-center h-32 w-40 mt-2">
                                                        <label
                                                            htmlFor={"dropzone-file-client-top"}
                                                            className="flex flex-col justify-center items-center w-full p-5 bg-gray-100 rounded-lg border-2 border-gray-light border-dashed cursor-pointer "
                                                        >
                                                            <div className="flex flex-col justify-center items-center">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="mb-3 w-6 h-6 text-gray-light"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    viewBox="0 0 24 24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                                    ></path>
                                                                </svg>
                                                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                                                    <span className="font-semibold">
                                                                        Click to upload
                                                                    </span>
                                                                </p>
                                                                <p className="mb-2 text-sm text-gray dark:text-gray">
                                                                    or drag and drop
                                                                </p>
                                                            </div>
                                                            <input
                                                                id={"dropzone-file-client-top"}
                                                                type="file"
                                                                className="hidden"
                                                                name={"dropzone-file-client-top"}
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => {
                                                                    func_attachFile(e.target, x);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>
                        ))}
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className='w-full flex flex-col gap-y-2'>
                            <div>
                                <label for="compare_name">รายการ <span style={{ color: 'red' }}>*</span></label>
                                <input className='form-control' onChange={e => set_job_compare_state({ ...job_compare_state, compare_name: e.target.value })} value={job_compare_state.compare_name} id="compare_name" required readOnly={ind == 0} />
                            </div>
                            <div>
                                <label for="property_type" className='form-control' >ประเภท</label>
                                <select value={job_compare_state.property_type} onChange={e => set_job_compare_state({ ...job_compare_state, property_type: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {jobtype_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="building_type" className='form-control' >ประเภทสิ่งปลูกสร้าง</label>
                                <select value={job_compare_state.building_type} onChange={e => set_job_compare_state({ ...job_compare_state, building_type: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {building_type_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="floor_amount" className='form-control' >จำนวน (ชั้น)</label>
                                <select value={job_compare_state.floor_amount} onChange={e => set_job_compare_state({ ...job_compare_state, floor_amount: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {floor_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="unit_amount">จำนวน</label>
                                <input className='form-control' onChange={e => set_job_compare_state({ ...job_compare_state, unit_amount: e.target.value })} value={job_compare_state.unit_amount} id="unit_amount" />
                            </div>
                            <div>
                                <label for="unit_unit" className='form-control' >หน่วย</label>
                                <select value={job_compare_state.unit_unit} onChange={e => set_job_compare_state({ ...job_compare_state, unit_unit: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {unit_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="usage_area">พื้นที่ใช้สอยภายในอาคาร (ตรม.)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, usage_area: e.target.value })}
                                    value={job_compare_state.usage_area} id="usage_area" type="number" />
                            </div>
                            <div>
                                <label for="building_size_width">ขนาดอาคาร กว้าง</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, building_size_width: e.target.value })}
                                    value={job_compare_state.building_size_width} id="building_size_width" type="number" />
                            </div>
                            <div>
                                <label for="building_size_length">ขนาดอาคาร ยาว</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, building_size_length: e.target.value })}
                                    value={job_compare_state.building_size_length} id="building_size_length" type="number" />
                            </div>
                            <div>
                                <label for="building_age">อายุอาคาร</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, building_age: e.target.value })}
                                    value={job_compare_state.building_age} id="building_age" type="number" />
                            </div>
                            <div>
                                <label for="building_address">ที่ตั้ง</label>
                                <textarea className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, building_address: e.target.value })}
                                    value={job_compare_state.building_address} id="building_address" rows={3}></textarea>
                            </div>
                            <div>
                                <label for="lat">จุดพิกัด GPS.- (LAT) <button className='ml-2' onClick={() => func_getCurrentLocation()}><ArrowPathIcon className="w-4 h-4 " /></button></label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, lat: e.target.value })} readOnly
                                    value={job_compare_state.lat} id="lat" />
                            </div>
                            <div>
                                <label for="long">จุดพิกัด GPS.- (LONG)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, long: e.target.value })} readOnly
                                    value={job_compare_state.long} id="long" />
                            </div>
                            <div className='grid grid-cols-3'>
                                <div>
                                    <label for="land_area_rai1">เนื้อที่ดิน (ไร่)</label>
                                    <input className='form-control'
                                        onChange={e => set_job_compare_state({ ...job_compare_state, land_area_rai1: e.target.value })}
                                        value={job_compare_state.land_area_rai1} id="land_area_rai1" type="number" />
                                </div>
                                <div>
                                    <label for="land_area_rai2">-</label>
                                    <input className='form-control'
                                        onChange={e => set_job_compare_state({ ...job_compare_state, land_area_rai2: e.target.value })}
                                        value={job_compare_state.land_area_rai2} id="land_area_rai2" type="number" />
                                </div>
                                <div>
                                    <label for="land_area_rai3">-</label>
                                    <input className='form-control'
                                        onChange={e => set_job_compare_state({ ...job_compare_state, land_area_rai3: e.target.value })}
                                        value={job_compare_state.land_area_rai3} id="land_area_rai3" type="number" />
                                </div>
                            </div>
                            <div>
                                <label for="land_area_wah">เนื้อที่ดิน (ตารางวา)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, land_area_wah: e.target.value })}
                                    value={job_compare_state.land_area_wah} id="land_area_wah" type="number" />
                            </div>
                            <div>
                                <label for="land_type" className='form-control' >ลักษณะรูปแปลงที่ดิน</label>
                                <select value={job_compare_state.land_type} onChange={e => set_job_compare_state({ ...job_compare_state, land_type: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {land_form_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="land_size_width">ขนาดของที่ดิน (หน้ากว้าง)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, land_size_width: e.target.value })}
                                    value={job_compare_state.land_size_width} id="land_size_width" type="number" />
                            </div>
                            <div>
                                <label for="land_size_length">ขนาดของที่ดิน (ลึกประมาณ)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, land_size_length: e.target.value })}
                                    value={job_compare_state.land_size_length} id="land_size_length" type="number" />
                            </div>
                            <div>
                                <label for="land_condition" className='form-control' >สภาพที่ดิน</label>
                                <select value={job_compare_state.land_condition} onChange={e => set_job_compare_state({ ...job_compare_state, land_condition: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {land_condition_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="ground_level" className='form-control' >ระดับดิน</label>
                                <select value={job_compare_state.ground_level} onChange={e => set_job_compare_state({ ...job_compare_state, ground_level: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {avg_level_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="ground_level_amount">ระดับดิน (เมตร)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, ground_level_amount: e.target.value })}
                                    value={job_compare_state.ground_level_amount} id="ground_level_amount" type="number" />
                            </div>
                            <div>
                                <label for="road_conditions" className='form-control' >สภาพถนนผ่านหน้าที่ดิน</label>
                                <select value={job_compare_state.road_conditions} onChange={e => set_job_compare_state({ ...job_compare_state, road_conditions: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {road_surface_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="road_conditions_amount">สภาพถนนผ่านหน้าที่ดิน (เมตร)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, road_conditions_amount: e.target.value })}
                                    value={job_compare_state.road_conditions_amount} id="road_conditions_amount" type="number" />
                            </div>
                            <div>
                                <label for="utilities" className='form-control' >สาธารณูปโภค</label>
                                <select value={job_compare_state.utilities} onChange={e => set_job_compare_state({ ...job_compare_state, utilities: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {utility_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="facilities">สิ่งอำนวยความสะดวก</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, facilities: e.target.value })}
                                    value={job_compare_state.facilities} id="facilities" />
                            </div>
                            <div>
                                <label for="environment" className='form-control' >สภาพแวดล้อม</label>
                                <select value={job_compare_state.environment} onChange={e => set_job_compare_state({ ...job_compare_state, environment: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {environment_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="city_plan_limit" className='form-control' >ข้อจำกัดผังเมือง</label>
                                <select value={job_compare_state.city_plan_limit} onChange={e => set_job_compare_state({ ...job_compare_state, city_plan_limit: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {city_plan_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="land_usage" className='form-control' >การใช้ประโยชน์ในที่ดิน</label>
                                <select value={job_compare_state.land_usage} onChange={e => set_job_compare_state({ ...job_compare_state, land_usage: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {land_benefit_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="development_potential" className='form-control' >ศักยภาพในการพัฒนา</label>
                                <select value={job_compare_state.development_potential} onChange={e => set_job_compare_state({ ...job_compare_state, development_potential: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {dev_potential_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="price">ราคาเสนอขาย / ซื้อ-ขายแล้ว (หลังละ)</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, price: e.target.value })}
                                    value={job_compare_state.price} id="price" type="number" />
                            </div>
                            <div>
                                <label for="sale_status" className='form-control' >สถานะการขาย</label>
                                <select value={job_compare_state.sale_status} onChange={e => set_job_compare_state({ ...job_compare_state, sale_status: e.target.value })}>
                                    <option value="">กรุณาเลือก...</option>
                                    {sell_status_state.map((x) => (
                                        <option value={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label for="inform_date">วันที่ได้รับข้อมูล</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, inform_date: e.target.value })}
                                    value={job_compare_state.inform_date} id="inform_date" type="date" />
                            </div>
                            <div>
                                <label for="informer">ผู้ขาย / ผู้ให้ข้อมูล</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, informer: e.target.value })}
                                    value={job_compare_state.informer} id="informer" />
                            </div>
                            <div>
                                <label for="phone">เบอร์โทรศัพท์ติดต่อ</label>
                                <input className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, phone: e.target.value })}
                                    value={job_compare_state.phone} id="phone" />
                            </div>
                            <div>
                                <label for="remark">หมายเหตุ</label>
                                <textarea className='form-control'
                                    onChange={e => set_job_compare_state({ ...job_compare_state, remark: e.target.value })}
                                    value={job_compare_state.remark} id="remark" rows={3}></textarea>
                            </div>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
            <div className='flex gap-x-4'>
                <button onClick={() => {
                    navigate(`/detail?job=${jobRef}`)
                }} className="btn-green !bg-white text-black flex-1 !bg-gray-800">ย้อนกลับ</button>
                <button onClick={(e) => on_submit_compare(e)} className="btn-green flex-1 !bg-gray-800">บันทึกข้อมูล</button>
            </div>
        </div>
    </div>
}

export default NewItem;