// import AlertError from "../reusable/AlertError";
import CSwl from "./alert";

export const CheckFile = ({ file, size, type = [], message = "" }) => {
  console.log("file.type::",file.type);
  if (!type.includes(file.type) && type.length > 0) {
    // AlertError(
    //   null,
    //   message ? message : `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
    // );
    CSwl.SwalErr(
      message ? message : `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
    );
    return false;
  }
  if (file.size / 1000 / 1000 > size) {
    // AlertError(null, `Please upload a file smaller than ${size} MB`);
    CSwl.SwalErr(
      `Please upload a file smaller than ${size} MB`
    );
    return false;
  } else {
    return true;
  }
};

export const CheckFileLogo = ({ file, size, type = [], message = "" }) => {
  console.log("file.type::",file.type);
  if (!type.includes(file.type) && type.length > 0) {
    CSwl.SwalErr(
      message ? message : `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
    );
    return false;
  }
  if (file.size / 200 / 200 > size) {
    // AlertError(null, `Please upload a file smaller than ${size} MB`);
    CSwl.SwalErr(
      `Please upload a file smaller than ${size} MB`
    );
    return false;
  } else {
    return true;
  }
};

export const ConvertFileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
