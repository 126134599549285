import Swal from "sweetalert2";

const SwalLoad = () => {
  Swal.fire({
    icon: "info",
    html: "Loading... Please Wait",
    willOpen: () => {
      Swal.showLoading();
    },
    showConfirmButton: false,
    allowOutsideClick: false,
  });
};
const SwalErr = async (text) => {
  var res = await Swal.fire({
    icon: "error",
    html: text,
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: "OK",
  });
  return res;
};
const SwalSuccess = async (text) => {
  var res = await Swal.fire({
    icon: "success",
    html: text,
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: "OK",
  });

  return res;
};

const SwalConfirm = async (text, { html = "" }) => {
  var result = await Swal.fire({
    title: text,
    html: html,
    icon: "warning",
    customClass: {
      confirmButton: "btn btn-success ml-2",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
    reverseButtons: true,
    showCancelButton: true,
    allowOutsideClick: false // disable click outside
  });

  return result;
};

const SwalClose = () => {
  Swal.close();
};

const CSwl = {
  SwalLoad: SwalLoad,
  SwalErr: SwalErr,
  SwalClose: SwalClose,
  SwalSuccess: SwalSuccess,
  SwalConfirm: SwalConfirm,
};

export default CSwl;
