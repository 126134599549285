import axios from "axios";
import { getLocalStorage } from "./localStorage";
// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

export const setHeaderAuth = async () => {
  const token = await getLocalStorage("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const setHeaderFromDataAuth = async () => {
  const token = await getLocalStorage("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
};


export const CurrencyThai = (number = 0, digits = 2) => {
  return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB', maximumFractionDigits: digits }).format(isNaN(number) ? 0 : number).replace("฿", "");
}
