import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StaticImage } from "../../utils/image";
import { PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import apiTranJob from "../../api/apiTranJob";
import CSwl from '../../utils/alert';
import { IMAGE_BACKEND_API } from "./../../env";

const Detail = () => {
    const [jobRef, setJobRef] = useState((new URL(window.location)).searchParams.get("job"));
    const [job_state, set_job_state] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        if (jobRef && jobRef.length > 0) {
            getDetail();
        }

        return () => { };
    }, []);

    const getDetail = async () => {
        try {
            CSwl.SwalLoad();
            const result = await apiTranJob.GetJobDetail("", jobRef);
            CSwl.SwalClose();

            if (result.status == 200) {
                const { data } = result;
                console.log("data::", data);
                var job_compare = [...data.job_compare_data];
                for (let i = 0; i < job_compare.length; i++) {
                    var item = job_compare[i];
                    if (!item.image_json) {
                        item.image_json = [];
                    }
                    if (item.image_json == "[]" || item.image_json == "\"[]\"" || item.image_json == "" || item.image_json == null) {
                        item.image_json = [];
                    }
                    while (typeof (item.image_json) != typeof ({})) {
                        item.image_json = JSON.parse(item.image_json);
                    }
                }
                setState(job_compare.filter((x) => x.compare_name == "ทรัพย์สินที่ประเมินมูลค่า"));
                setState2(job_compare.filter((x) => x.compare_name != "ทรัพย์สินที่ประเมินมูลค่า"))
                // set_job_deed_data(data.deed_data ?? []);
                // set_job_sign_data(data.sign_data ?? []);
                // set_job_valuation_state(data.bd_valuation_json ?? [])
                delete data.job_compare_data;
                delete data.deed_data;
                delete data.sign_data;
                delete data.bd_valuation_json;
                set_job_state(data);
                // var pdata = [];
                // for (let i = 0; i < data.rows.length; i++) {
                //     var item = data.rows[i];
                //     pdata.push({
                //         value: item.contractname,
                //         label: item.contractname,
                //     })
                // }
                // setCustomerOptionData(pdata)
                // setCustomerData(data);
            }
        } catch (error) {

        }
    };

    const onSubmitData = (e, type) => {
        e.preventDefault();
        var model = job_state;
        model.status = "5";
        saveData(model);
    };

    const onRejectData = (e, type) => {
        e.preventDefault();
        
        CSwl.SwalConfirm('', { html: `ต้องการจะยกเลิกการรับงาน ใช่หรือไม่?` }).then(async (result) => {
            if (result.isConfirmed) {
            CSwl.SwalLoad();
            try {
                var model = job_state;
                model.status = "10";
                saveData(model);
            } catch (error) {
    
            }
            CSwl.SwalClose();
            }
        });
    };

    const saveData = async (data) => {
        try {
            const result = await apiTranJob.SaveJobStatus(data, "Save job Data");
            console.log(result)
            if (result.status === 200) {
                // const { data } = result.data;
                // toggle();
                CSwl.SwalSuccess("บันทึกสำเร็จ").then((success) => {
                    navigate('/')
                });
            }
        } catch (error) {
            CSwl.SwalErr(error.response.data)
        }
    };


    const [state, setState] = useState([]);
    const [state2, setState2] = useState([]);
    return <div className="p-3 py-4">
        <div className={`border rounded py-2 px-3 bg-white`}>
            <div className="flex flex-col flex-1">
                <h4 className="text-xl">{job_state.customer_name} <span className="text-gray-600">({job_state.job_no})</span></h4>
                <div className="grid grid-cols-2 gap-x-3">
                    <span className="text-gray-600 text-sm">{job_state.assessment_objectives}</span>
                    <span className="text-gray-600 text-sm">{job_state.assessment_branch}</span>
                </div>
                <div className="flex gap-x-2 border-t mt-1">
                    <span className="text-gray-600 text-sm">วันที่รับงาน {job_state.receive_date}</span>
                </div>
                <div className="flex gap-x-2">
                    <span className="text-gray-600 text-sm">วันที่สำรวจ(นัดหมาย) {job_state.survey_date}</span>
                </div>
                <div className="flex gap-x-2">
                    <span className="text-gray-600 text-sm">กำหนดส่งงาน {job_state.due_date}</span>
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-y-4">
            <div>
                <h4 className="mb-1 ">ทรัพย์สินที่ประเมินมูลค่า</h4>
                {state.map((item, index) => {
                    return <CardItem item={item} job_state={job_state}/>
                })}
                <div className="justify-between flex items-center mt-4 mb-2">
                    <h4 >ทรัพย์สินที่นำมาเปรียบเทียบ</h4>
                    <button onClick={() => {
                            navigate(`/newItem?job=${job_state.job_no}`)
                    }} className="btn-green !bg-green-800 h-fit text-sm flex items-center gap-x-2">
                        <PlusIcon className="w-4" />
                        เพิ่มข้อมูลเปรียบเทียบ
                    </button>
                </div>
                <div className="gap-y-2 flex flex-col">
                    {state2.map((item, index) => {
                        return <CardItemSec item={item} job_state={job_state} index={index}/>
                    })}
                </div>
            </div>
        </div>
        {job_state.status == "3" ? (
            <>
                <div className='flex gap-x-4 mt-4'>
                    <button onClick={() => {
                        navigate('/')
                    }} className="btn-green !bg-white text-black flex-1 !bg-gray-800">ย้อนกลับ</button>
                    <button onClick={(e) => onRejectData(e)} className="btn-green flex-1 !bg-red-600">ยกเลิกการรับงาน</button>
                </div>
                <div className='flex gap-x-4 mt-4'>
                    <button onClick={(e) => onSubmitData(e)} className="btn-green flex-1 !bg-gray-800">ยืนยันการส่งข้อมูล</button>
                </div>
            </>
        ) : (
            <div className='flex gap-x-4 mt-4'>
                <button onClick={() => {
                        navigate('/')
                    }} className="btn-green !bg-white text-black flex-1 !bg-gray-800">ย้อนกลับ</button>
            </div>
        )}
    </div >
}

const CardItem = ({ item, job_state }) => {
    const navigate = useNavigate();
    return <div className={`border rounded py-2 px-3 flex gap-x-4 bg-white`}>
        <div className="flex flex-col gap-y-2">
            <img className="w-20 h-20" src={IMAGE_BACKEND_API + item.cover_image} />
            {/* <button onClick={() => {
                navigate('/detail')
            }} className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">รายละเอียด</button> */}
        </div>
        <div className="flex flex-col flex-1">
            <div className="flex justify-between">
                <h4 className="text-xl">{item.customer_name}</h4>
                {job_state.status == "3" && (
                    <div className="flex gap-x-2">
                        <div onClick={() => {
                            navigate(`/newItem?job=${item.job_no}&id=${item.id}&ind=0`)
                        }} className="flex items-center gap-x-1 text-blue-600 cursor-pointer">
                            <PencilSquareIcon className="w-4 h-4 " />
                            <span>แก้ไข</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex gap-x-2">
                <span className="flex-1">{item.building_type} {item.floor_amount} {item.unit_amount} {item.unit_unit} {item.land_area_wah} ตรว.</span>
            </div>
            <div className="flex gap-x-2 mt-1">
                <span className="text-gray-600 text-sm">{item.building_address}</span>
            </div>
            <div className="flex flex-col text-gray-600">
                <span>พื้นที่ใช้สอย : {item.usage_area} ตรม</span>
                <span>ขนาดอาคาร : {item.building_size_width} x {item.building_size_length} เมตร</span>
            </div>
        </div>
    </div>
}

const CardItemSec = ({ item, job_state, index }) => {
    const navigate = useNavigate();
    return <div className={`border rounded py-2 px-3 flex gap-x-4 bg-white`}>
        <div className="flex flex-col gap-y-2">
            <img className="w-20 h-20" src={IMAGE_BACKEND_API + item.cover_image} />
            {/* <button onClick={() => {
                navigate('/detail')
            }} className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">รายละเอียด</button> */}
        </div>
        <div className="flex flex-col flex-1">
            <div className="flex justify-between">
                <h4 className="text-xl">{index + 1 + ". " + item.compare_name}</h4>
                {job_state.status == "3" && (
                    <div className="flex gap-x-2">
                        <div onClick={() => {
                            navigate(`/newItem?job=${item.job_no}&id=${item.id}`)
                        }} className="flex items-center gap-x-1 text-blue-600 cursor-pointer">
                            <PencilSquareIcon className="w-4 h-4 " />
                            <span>แก้ไข</span>
                        </div>
                        <div className="flex items-center gap-x-1 text-red-600">
                            <TrashIcon className="w-4 h-4 " />
                            <span>ลบ</span>
                        </div>
                    </div>
                )}
                </div>
            <div className="flex gap-x-2">
                <span className="flex-1">{item.building_type} {item.floor_amount} {item.unit_amount} {item.unit_unit} {item.land_area_wah} ตรว.</span>
            </div>
            <div className="flex gap-x-2 mt-1">
                <span className="text-gray-600 text-sm">{item.building_address}</span>
            </div>
            <div className="flex flex-col text-gray-600">
                <span>พื้นที่ใช้สอย : {item.usage_area} ตรม</span>
                <span>ขนาดอาคาร : {item.building_size_width} x {item.building_size_length} เมตร</span>
            </div>
        </div>
    </div>
}


export default Detail;