import axios from "axios";
import { setHeaderAuth, setHeaderFromDataAuth } from "../utils";

const GetList = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterJobType/get`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const GetById = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterJobType/getById`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const InsertMtJobType = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterJobType/insert`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateMtJobType = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterJobType/update`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateStatus = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterJobType/status`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const DeleteMtJobType = async (id,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterJobType/${id}`,
      method: "delete",
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const ImportFile = async (data, activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      headers: {
          'Content-Type': 'multipart/form-data',
          activity_detail: activity_detail
      },
      url: `masterJobType/import`,
      method: "post",
      data: data
  });
  return res;
};
const Export = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterJobType/export`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};

const apiMasterJobType = {
  GetList: GetList,
  GetById: GetById,
  InsertMtJobType: InsertMtJobType,
  UpdateMtJobType: UpdateMtJobType,
  UpdateStatus: UpdateStatus,
  DeleteMtJobType: DeleteMtJobType,
  Export: Export,
  ImportFile: ImportFile,
};
export default apiMasterJobType;