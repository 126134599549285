import axios from "axios";
import { setHeaderAuth } from "../utils";

const login = async (data) => {
  await setHeaderAuth();
  let res = await axios({
    url: "/users/login",
    method: "post",
    data: data,
  });
  return res;
};
const apiUser = {
  login: login,
};

export default apiUser;
