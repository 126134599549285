import moment from "moment";
import 'moment/locale/th';
import { useEffect, useState } from "react";
import { StaticImage } from "../../utils/image";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import apiTranJob from "../../api/apiTranJob";
import { IMAGE_BACKEND_API } from "./../../env";
import CSwl from '../../utils/alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';


const get_order_status = (status) => {
    switch (status) {
        case "1": return "Marketting Save draft";
        case "2": return "Marketting Send Request to Assessor";
        case "3": return "Marketting Withdraw";
        case "4": return "Onsite Save Draft";
        case "5": return "Onsite Complete";
        case "6": return "Assessor Save Draft";
        case "7": return "Assessor Send Job to admin";
        case "8": return "Admin Save Draft";
        case "9": return "Admin Send Job to Approver";
        default: return "";
    }
}

const Home = () => {
    const [state, setState] = useState([]);
    //     const [state, setState] = useState([
    //     {
    //         job_no: "TT2023090001TTLP001",
    //         customer_name: "นายนพรัตน์ บุญไชย",
    //         doc_no: "AG66081752202",
    //         customer_address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
    //         department: "ลาดพร้าว 6",
    //         status: "2",
    //         img: "home1.jpeg",
    //         assessment_branch: "ธนาคารไทยเครดิท",
    //         receive_date: moment("2023-09-20").format("DD MMMM yyyy เวลา HH:mm"),
    //         survey_date: moment("2023-09-20").format("DD MMMM yyyy เวลา HH:mm"),
    //         send_system_date: moment("2023-09-21").format("DD MMMM yyyy เวลา HH:mm"),
    //         assessment_objectives: "ทบทวนสินเชื่อ",
    //         land_area_wah: 20,
    //         building_type: "ทาวน์เฮ้าส์",
    //         floor_amount: "2 ชั้น",
    //         unit_amount: 2,
    //         unit_unit: "คูหา",
    //         deed: [
    //             {
    //                 title_deed: "11542",
    //                 deed_book: "",
    //                 deed_page: "",
    //                 land_title: "",
    //                 land_number: "",
    //                 explore_page: "",
    //                 land_area_rai1: "",
    //                 land_area_rai2: "",
    //                 land_area_rai3: "",
    //             },
    //             {
    //                 title_deed: "11543",
    //                 deed_book: "",
    //                 deed_page: "",
    //                 land_title: "",
    //                 land_number: "",
    //                 explore_page: "",
    //                 land_area_rai1: "",
    //                 land_area_rai2: "",
    //                 land_area_rai3: "",
    //             }
    //         ]
    //     },
    //     {
    //         job_no: "TT2023090002TTLP001",
    //         customer_name: "นายอัชพล พึ่งเสมา",
    //         doc_no: "AG66081752203",
    //         customer_address: "330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300",
    //         department: "ลาดพร้าว 6",
    //         status: "4",
    //         img: "home2.jpeg",
    //         assessment_branch: "ธนาคารทหารไทย",
    //         receive_date: moment("2023-09-20").format("DD MMMM yyyy เวลา HH:mm"),
    //         survey_date: moment("2023-09-20").format("DD MMMM yyyy เวลา HH:mm"),
    //         send_system_date: moment("2023-09-21").format("DD MMMM yyyy เวลา HH:mm"),
    //         due_date: moment("2023-09-21").format("DD MMMM yyyy เวลา HH:mm"),
    //         assessment_objectives: "ประกอบการพิจารณด้านสินเชื่อ",
    //         land_area_wah: 200,
    //         building_type: "บ้านเดี่ยว",
    //         floor_amount: "2 ชั้น",
    //         unit_amount: 1,
    //         unit_unit: "หลัง",
    //         deed: [
    //             {
    //                 title_deed: "5362",
    //                 deed_book: "",
    //                 deed_page: "",
    //                 land_title: "",
    //                 land_number: "",
    //                 explore_page: "",
    //                 land_area_rai1: "",
    //                 land_area_rai2: "",
    //                 land_area_rai3: "",
    //             }
    //         ]
    //     },
    // ]);

    useEffect(() => {
      getData("Click Menu Tran Order Items");
    //   func_loadcateDDL();
    //   func_loadSubcateDDL();
    //   getDataSenderZipcode()
    //   getDataRecvZipcode()
      // func_add_log("Click Menu Invoice", "{}");
  
      return () => { };
    }, []);

    const getData = async (activity_detail = '') => {
      try {
  
        var model = {
          job_no: "",
          role: "R05"
        }
        // CSwl.SwalLoad();
        const result = await apiTranJob.GetList(model, activity_detail);
        // CSwl.SwalClose();
  
        if (result.status == 200) {
          const { data } = result;
          console.log("data::", data);
          setState(data.rows);
        }
        } catch (error) { }
    };
  

    return <div className="p-3 py-4">
        <div className={`border border-l-yellow-500 border-l-8 rounded`}>
            <h4 className="text-xl pl-3"> WORK IN PROCESS </h4>
        </div>
        <div className="flex flex-col gap-y-4 mt-2">
            {state.filter((x) => (x.status == "3" || x.status == "4")).map((item, index) => {
                return <CardItem item={item} state={state} setState={setState} />
            })}
        </div>
        <div className={`border border-l-gray-500 border-l-8 rounded mt-2`}>
            <h4 className="text-xl pl-3"> INCOMING WORK </h4>
        </div>
        <div className="flex flex-col gap-y-4 mt-2">
            {state.filter((x) => x.status == "2").map((item, index) => {
                return <CardItem item={item} state={state} setState={setState} />
            })}
        </div>
    </div>
}

const CardItem = ({ item, state, setState }) => {
    const navigate = useNavigate();
    
    const onAcceptJob = (e, model) => {
        e.preventDefault();
        var oldData = [...state];
        var ind = oldData.findIndex((x) => x.id == item.id);
        console.log("model::", model);
        oldData[ind].is_on_accept = true;
        setState(oldData);
    };
    const onCancelSubmitData = (e, model) => {
        e.preventDefault();
        var oldData = [...state];
        var ind = oldData.findIndex((x) => x.id == item.id);
        console.log("model::", model);
        oldData[ind].is_on_accept = false;
        setState(oldData);
    };

    const onSubmitData = (e, model) => {
        e.preventDefault();
        model.status = "3";
        saveData(model);
    };

    const onRejectData = (e, model) => {
        e.preventDefault();
        
        CSwl.SwalConfirm('', { html: `ต้องการจะปฎิเสธงาน ใช่หรือไม่?` }).then(async (result) => {
            if (result.isConfirmed) {
            CSwl.SwalLoad();
            try {
                model.status = "10";
                saveData(model);
            } catch (error) {
    
            }
            CSwl.SwalClose();
            }
        });
    };

    const saveData = async (data) => {
        try {
            const result = await apiTranJob.SaveJobStatus(data, "Save job Data");
            console.log(result)
            if (result.status === 200) {
                // const { data } = result.data;
                // toggle();
                CSwl.SwalSuccess("บันทึกสำเร็จ").then((success) => {
                    navigate(`/detail?job=${data.job_no}`)
                });
            }
        } catch (error) {
            CSwl.SwalErr(error.response.data)
        }
    };
    return <div className={`border ${(item.status == "4" || item.status == "3") ? "border-l-yellow-500" : "border-l-gray-500"} border-l-4 rounded py-2 px-3 bg-white`}>
            <div className="flex flex-row gap-x-4">
                <div className="flex flex-col gap-y-2">
                    <img className="w-20 h-20" src={IMAGE_BACKEND_API + item.cover_image} />
                    <button onClick={() => {
                        navigate(`/detail?job=${item.job_no}`)
                    }} className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">รายละเอียด</button>
                </div>
                <div className="flex flex-col">
                    <div className="gap-y-2">
                        <h4 className="text-xl">{item.customer_name} <span className="text-gray-600">({item.job_no})</span></h4>
                        <div className="grid grid-cols-2 gap-x-3">
                            <span className="text-gray-600 text-sm">{item.assessment_objectives}</span>
                            <span className="text-gray-600 text-sm">{item.assessment_branch}</span>
                        </div>
                        <div className="flex gap-x-2 ">
                            <span className="text-gray-600 text-sm">{item.building_type} {item.floor_amount} {item.unit_amount} {item.unit_unit} {item.land_area_wah} ตรว.</span>
                        </div>
                        <div className="flex gap-x-2 mt-1">
                            <span className="text-gray-600 text-sm">{item.customer_address}</span>
                        </div>
                        <div className="flex gap-x-2 border-t mt-1">
                            <span className="text-gray-600 text-sm">วันที่รับงาน {item.receive_date}</span>
                        </div>
                        <div className="flex gap-x-2">
                            <span className="text-gray-600 text-sm">วันที่สำรวจ(นัดหมาย) {item.survey_date}</span>
                        </div>
                        <div className="flex gap-x-2">
                            <span className="text-gray-600 text-sm">กำหนดส่งงาน {item.due_date}</span>
                        </div>
                        <div className="flex gap-x-2 border-t mt-1">
                            <span className="text-sm text-gray-600">{get_order_status(item.status)}</span>
                        </div>
                    </div>
                    {/* <div className="grid grid-rows-3 gap-x-3">
                        <div className="flex justify-between border-t pt-1">
                            <span className="text-sm text-gray-600">{get_order_status(item.status)}</span>
                            <span className="text-sm text-gray-600">{item.send_system_date}</span>
                        </div>
                    </div> */}
                </div>
            </div>
            {item.status == "2" && (
                <div className="flex flex-row gap-x-4 mt-2">
                    <button onClick={(e) => onAcceptJob(e, item)}
                    className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">รับงาน</button>
                    {item.is_on_accept == true && (
                        <div>
                            <label for="survey_date">วันที่สำรวจ(นัดหมาย)</label>
                            <DatePicker
                                selected={item.survey_date ? moment(item.survey_date).toDate() : null}
                                onChange={(date) => {
                                    // console.log("data::", date);
                                    console.log("data::", moment(date.setHours(7)).toDate());
                                    // console.log("data::", moment(date));
                                    // set_job_state({ ...job_state, survey_date: moment(date.setHours(7)).toDate() })
                                    var oldData = [...state];
                                    var ind = oldData.findIndex((x) => x.id == item.id);
                                    console.log("oldData[ind]::", oldData[ind]);
                                    oldData[ind].survey_date = moment(date.setHours(7)).toDate();
                                    console.log("oldData[ind]::", oldData[ind]);
                                    // console.log("survey_date:value:", e.target.value);
                                    setState(oldData);
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                            {/* <input className='form-control' 
                            onChange={e => {
                                var oldData = [...state];
                                var ind = oldData.findIndex((x) => x.id == item.id);
                                oldData[ind].survey_date = e.target.value;
                                console.log("survey_date:value:", e.target.value);
                                setState(oldData);
                            }} 
                            value={item.survey_date ? moment(moment(item.survey_date).toDate()).format("YYYY-MM-DD") : null} id="survey_date" type="date"/> */}
                        </div>
                    )}
                    {item.is_on_accept == true && (
                        <button onClick={(e) => onSubmitData(e, item)} className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">ยืนยัน</button>
                    )}
                    {item.is_on_accept == true && (
                        <button onClick={(e) => onCancelSubmitData(e, item)} className="btn-green !bg-red-600 text-xs leading-[0.7rem]">ยกเลิก</button>
                    )}
                    {item.is_on_accept != true && (
                        <button onClick={(e) => onRejectData(e, item)} className="btn-green !bg-red-600 text-xs leading-[0.7rem]">ปฎิเสธงาน</button>
                    )}
                </div>
            )}
            <div className="flex flex-row gap-x-4 mt-2">
                    <button className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">JOB</button>
                    <button className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">Apd</button>
                    <button className="btn-green !bg-gray-800 text-xs leading-[0.7rem]">map</button>
            </div>
        </div>
}

export default Home;