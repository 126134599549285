import { Link, useNavigate } from "react-router-dom";
import { StaticImage } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CSwl from '../../utils/alert';
import { setLocalStorage } from "../../utils/localStorage.js";
import apiUser from "../../api/apiUser.js";
const Login = () => {
    const [state, setstate] = useState({
        email: "",
        password: "",
        messageError: "",
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToHome = async (e) => {
        e.preventDefault();
        CSwl.SwalLoad();
        const response = await apiUser.login({
            "username": state.email,
            "password": state.password,
        });
        if (response.status == 200) {
            const user  = response.data.userData;
            const token = response.data.accessToken;
            dispatch({ type: "set", user: user, token: token });
            setLocalStorage("token", token);
            navigate('/');
            // const responseUser = await apiUser.getProfile();
            // if (responseUser.status == 200) {
            //     dispatch({ type: "set", user: user, token: token });
            //     navigate('/');
            // }
            CSwl.SwalClose();
        } else {
            CSwl.SwalClose();
            setstate({ ...state, messageError: response.data });
            //CSwl.SwalErr(response.data);
        }
        // dispatch({ type: "set", user: { email: state.email }, token: "" });
        // navigate('/');
    }

    return <>
        <form onSubmit={navigateToHome}>
            <section className="h-screen">
                <div className="h-full bg-gradient-to-r from-green-400 to-green-600 flex justify-center items-center">
                    <div className="p-5 bg-white rounded flex flex-col items-center">
                        <div>
                            <img className="object-contain w-40" src={StaticImage('/images/threetree-logo1.png')} />
                        </div>
                        <div>
                            <h1 className="font-bold text-3xl">Sign in to your account</h1>
                        </div>
                        <span className="text-base text-gray">ยินดีต้อนรับเข้าสู่ระบบประเมินดิจิตอล</span>
                        <input value={state.email}
                            onChange={(e) => {
                                setstate({ ...state, email: e.target.value })
                            }} required className="form-control w-72 mt-2" placeholder="Username" type="text" />
                        <input value={state.password}
                            onChange={(e) => {
                                setstate({ ...state, password: e.target.value })
                            }} required className="form-control w-72 mt-1" placeholder="Password" type="password" />
                        {state.messageError && <label className="text-red">{state.messageError}</label>}
                        {/* <div className="mt-2 flex justify-between w-72">
                            <label class="inline-flex items-center">
                                <input type="checkbox" class="form-checkbox" />
                                <span class="ml-2 text-lg">Remember me</span>
                            </label>
                            <span class="ml-2 text-lg text-blue-link">Forgot your password?</span>
                        </div> */}
                        {/* <Link to="/"> */}
                        <button type="submit" className="btn-green w-72 mt-2">Sign in</button>
                        {/* </Link> */}
                    </div>
                </div>
            </section>
        </form>
    </>
}
export default Login;