import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './pages/login';

import Layout from './components/Layout';
import Footer from './components/Footer';
// import Home from './pages/home';
// import Job from './pages/job';
import ScrollToTop from './utils/scrollToTop';
import Home from './pages/home';
import History from './pages/history';
import Detail from './pages/detail';
import NewItem from './pages/newItem';
// import JobTimeline from './pages/jobTimeline';
// import Check from './pages/check';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="history" element={<History />} />
          <Route path="detail" element={<Detail />} />
          <Route path="newItem" element={<NewItem />} />
          {/* <Route path="check" element={<Check />} /> */}
        </Route>
        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        {/* <Route path="*" element={<NoMatch />} /> */}
        <Route path="/login" element={<Login />} />
      </Routes>

    </BrowserRouter>
  );
}



export default App;
