import axios from "axios";
import { setHeaderAuth, setHeaderFromDataAuth } from "../utils";

const GetList = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterFloor/get`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const GetById = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
    url: `/masterFloor/getById`,
    method: "post",
    data: data,
    headers: {
      activity_detail: activity_detail
    }
  });
  return res;
};
const InsertMtFloor = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterFloor/insert`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateMtFloor = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterFloor/update`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const UpdateStatus = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterFloor/status`,
      method: "put",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const DeleteMtFloor = async (id,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterFloor/${id}`,
      method: "delete",
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};
const ImportFile = async (data, activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      headers: {
          'Content-Type': 'multipart/form-data',
          activity_detail: activity_detail
      },
      url: `masterFloor/import`,
      method: "post",
      data: data
  });
  return res;
};
const Export = async (data,activity_detail = '') => {
  await setHeaderAuth();
  let res = await axios({
      url: `/masterFloor/export`,
      method: "post",
      data: data,
      headers: {
        activity_detail: activity_detail
      }
  });
  return res;
};

const apiMasterFloor = {
  GetList: GetList,
  GetById: GetById,
  InsertMtFloor: InsertMtFloor,
  UpdateMtFloor: UpdateMtFloor,
  UpdateStatus: UpdateStatus,
  DeleteMtFloor: DeleteMtFloor,
  Export: Export,
  ImportFile: ImportFile,
};
export default apiMasterFloor;